import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function TheBadDays() {
  return (
    <Layout>
      <Helmet title="The Bad Days | David Ramirez" />

      <h1>The Bad Days</h1>

      <h2>David Ramirez</h2>

      <h4>Key: Am | Capo 2</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>
            <b>Am G C</b>
          </p>
          <p>
            <b>F C Em</b>
          </p>
          <p>
            <b>Am G C</b>
          </p>
          <p>
            <b>F G C</b>
          </p>
        </Verse>
        <Verse>
          <p>
            There's gonna be <Ch>Am</Ch>days when you <Ch>G</Ch>hate <Ch>C</Ch>
            me
          </p>
          <p>
            There's gonna be <Ch>F</Ch>days when I <Ch>C</Ch>make you{" "}
            <Ch>Em</Ch>mad
          </p>
          <p>
            There's gonna be <Ch>Am</Ch>days when you <Ch>G</Ch>don't know what
            we're <Ch>C</Ch>doing
          </p>
          <p>
            Maybe some <Ch>Am</Ch>days you wish you could <Ch>C</Ch>take it all
            <Ch>G</Ch>back
          </p>
        </Verse>
        <Verse>
          <p>
            There's gonna be <Ch>Am</Ch>days when the <Ch>G</Ch>love is so{" "}
            <Ch>Capo</Ch>thin
          </p>
          <p>
            The days are a <Ch>F</Ch>game that we <Ch>C</Ch>just can't{" "}
            <Ch>Em</Ch>win
          </p>
          <p>
            There's gonna be <Ch>Am</Ch>days you might <Ch>G</Ch>wanna be{" "}
            <Ch>C</Ch>free
          </p>
          <p>
            There's gonna be <Ch>Am</Ch>days that you <Ch>G</Ch>hate <Ch>C</Ch>
            me
          </p>
        </Verse>
        <Chorus>
          <p>
            But <Ch>F</Ch>hold on <Ch>C</Ch>strong
          </p>
          <p>
            And <Ch>F</Ch>don't let <Ch>C</Ch>go
          </p>
          <p>
            We'll <Ch>F</Ch>carry <Ch>C</Ch>on I <Ch>G</Ch>know we can{" "}
            <Ch>Am</Ch>find a <Ch>F</Ch>way
          </p>
          <p>
            'Cause you're still my girl in the <Ch>G</Ch>bad <Ch>C</Ch>days
          </p>
        </Chorus>
        <Verse>
          <p>
            There's gonna be <Ch>Am</Ch>days I haven't <Ch>G</Ch>seen you for
            <Ch>C</Ch>months
          </p>
          <p>
            Nights when my <Ch>F</Ch>lover is a <Ch>C</Ch>basement <Ch>Em</Ch>
            club
          </p>
          <p>
            There's gonna be <Ch>Am</Ch>weeks when you're <Ch>G</Ch>all{" "}
            <Ch>C</Ch>alone
          </p>
          <p>
            Because of the<Ch>F</Ch>days I'm chained to that <Ch>C</Ch>open
            <Ch>G</Ch>road
          </p>
        </Verse>
        <Verse>
          <p>
            And I hope the <Ch>Am</Ch>years that we <Ch>G</Ch>come up <Ch>C</Ch>
            short
          </p>
          <p>
            Are swallowed by the <Ch>F</Ch>years that we <Ch>C</Ch>make it{" "}
            <Ch>Em</Ch>work
          </p>
          <p>
            And I pray the <Ch>Am</Ch>times that our <Ch>G</Ch>love is{" "}
            <Ch>C</Ch>sweet
          </p>
          <p>
            Outweigh the <Ch>F</Ch>days that you <Ch>G</Ch>hate <Ch>C</Ch>me
          </p>
        </Verse>
        <Chorus>
          <p>
            But <Ch>F</Ch>hold on <Ch>C</Ch>strong
          </p>
          <p>
            And <Ch>F</Ch>don't let <Ch>C</Ch>go
          </p>
          <p>
            We'll <Ch>F</Ch>carry <Ch>C</Ch>on I <Ch>G</Ch>know we can{" "}
            <Ch>Am</Ch>find a <Ch>F</Ch>way
          </p>
          <p>
            'Cause you're still my girl in the <Ch>G</Ch>bad <Ch>F</Ch>days
          </p>
        </Chorus>
        <Bridge>
          <p>
            <Ch>G</Ch>Ohhhh
          </p>
          <p>
            The bad <Ch>Am</Ch>days
          </p>
          <p>
            <Ch>C</Ch>(Daaaays)
          </p>
          <p>
            The <Ch>F</Ch>bad days
          </p>
          <p>
            <Ch>G</Ch>Ohhhh
          </p>
        </Bridge>
        <Chorus>
          <p>
            And <Ch>F</Ch>hold on <Ch>C</Ch>strong
          </p>
          <p>
            And <Ch>F</Ch>don't let <Ch>C</Ch>go
          </p>
          <p>
            We'll <Ch>F</Ch>carry <Ch>C</Ch>on I <Ch>G</Ch>know we can{" "}
            <Ch>Am</Ch>find a <Ch>F</Ch>way
          </p>
          <p>
            'Cause you're still my girl in the <Ch>G</Ch>bad <Ch>Am</Ch>days
          </p>
          <p>
            'Cause you're still my girl in the <Ch>G</Ch>bad <Ch>C</Ch>days
          </p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
